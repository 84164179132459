// Colours
$white: #ffffff;
$off-white: #E6E8EB;
$sheer-white: rgba(255, 255, 255, 0.223);
$black: #000000;
$light-grey: #dfdfdf;
$dark-grey: #212121;

$devider: rgba(255, 255, 255, 0.816);


$primary: #bdd8f2;
$secondary: #232323;

$background: #021e33;
$secondary-background:#0D273B;
$light-background:#E6E8EB;

$positive: #0ab04b;
$negative: #e64747;

$label: #1c4c56;
$input-bg: #ffffff;
$input-border: #dfdfdf;
$input-font: #212121;

// Spacing
$btn-padding: 0.75em 2em;

// Typography
$heading: montserrat, sans-serif;
$font: montserrat, sans-serif;


// transition

$default-transition: all 0.25s ease;
