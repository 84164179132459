.expandable {
	width: 100%;
    a {
        color: $background!important;
    }

    .expandable-title {
		display: block;
		position: relative;
        background: $off-white;
		padding: 0.8rem 3rem 0.8rem 1rem;
		border-radius: 50px;
        border: $background 2px solid;
		transition: all 0.25s ease;
        font-size: 1rem;
        line-height: 1.7rem;
        font-weight: 700;
		cursor: pointer;

		&:not(:first-child) {
			margin-top: 0.5rem;
		}

		&::after {
			content: '';
			position: absolute;
			top: 50%; right: 1.25rem;
			background: $background;
			mask: url('/img/icons/chevron-down-solid.svg') center center / 1rem no-repeat;
			width: 1rem;
			height: 1rem;
			transition: all 0.25s ease;
			transform: translateY(-50%);
			text-align: center;
			font-weight: 400;
			font-size: 87.5%;
		}

		&.active {
			background: $background;
			color: $off-white;

			&::after {
				background: $off-white;
				transform: translateY(-50%) rotate(180deg);
			}
		}

		&:hover {
			background: $background;
			color: $off-white;

			&::after {
				background: $off-white;
			}
		}
	}

	.expandable-content {
		display: none;
		padding: 0.75rem 1rem;

		> :last-child {
			margin-bottom: 0;
		}
	}
}
